<template>

  <div class="bg-primary min-h-screen flex flex-col justify-center items-center space-y-8">
    <div class="text-secondary tracking-widest" style="font-size: 200px">
      404
    </div>
    <div class="text-secondary text-md">
      Erreur de page
    </div>
    <NuxtLink to="/" class="relative w-max px-8 py-4 bg-secondary text-primary text-center group">
      <span class="absolute left-0 bottom-0 h-0 group-hover:h-full bg-white transition-all ease-out duration-300 w-full"></span>
      <span class="relative">
        Retour à la page d'accueil
      </span>
    </NuxtLink>
  </div>
</template>

<script setup>

</script>